import React from 'react'

export default () => (
    <>
        <a><i className="fab fa-facebook"></i></a>
        <a><i className="fab fa-twitter"></i></a>
        <a><i className="fab fa-instagram"></i></a>
        <a><i className="fab fa-spotify"></i></a>
        <a><i className="fab fa-amazon"></i></a>
    </>
)
